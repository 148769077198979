<template>
  <v-container style="margin-top:250px">
    <v-row justify="center">
      <v-alert
        dense
        outlined
        type="info"
      >
      EL SISTEMA REQUIERE AUTENTICACIÓN
      </v-alert>
    </v-row>
    <v-row>
      <v-layout align-center justify-center>
          <v-col sm="4" lg="3">
            <v-card class="elevation-12">
              <v-form @submit.prevent="login()">
                <v-toolbar dark color="primary">
                  <v-toolbar-title>Login</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                      <v-text-field
                        v-model="username"
                        prepend-icon="mdi-account"
                        name="login"
                        label="Usuario"
                        type="text"
                        data-layout="normal"
                      ></v-text-field>
                      <v-text-field
                        v-model="password"
                        id="password"
                        prepend-icon="mdi-lock"
                        name="password"
                        label="Password"
                        type="password"
                      ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit" :disabled="$store.disableBtn" >Login</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
      </v-layout>
    </v-row>
  </v-container>
</template>

<script>
  import MD5 from "crypto-js/md5";
  export default {
    name: 'PagesLogin',

    data: () => ({
      username: '',
      password: '',
    }),
    mounted () {
/*       this.$EventBus.$on("input", (data) => {
        this.$nextTick(() => {                    
           if (data.inputName === 'login') {
             this.username = data.value;
           } 
           if (data.inputName === 'password') {
             this.password = data.value;
           }
        });   
      })
      this.login() */
    },      
    methods: {
      validateForm () {
        this.disabled = true
        this.$store.commit('loaderOn', 1)
        this.login()
      },
      async login() {
          try {
            await this.$store.dispatch('retrieveToken', {
              username: this.username,
              password: MD5(this.password).toString(),
            });

            const response = await this.$store.dispatch('axiosMod/getData', { url: '/SAT_WS/rest/usuario/cuit/' + this.username })
            localStorage.setItem('userId', response.id)
            this.$router.push({ name: 'Home' })
          } catch (error) {
              console.error('Error:', error.message);
              if (error.response) {
                if (error.response.status == 401)
                  alert("Credenciales incorrectas")
              } else {
                  alert("Ocurrió un error")
              }        
          }
        },
    },
  }
</script>